import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import LuckDraw from 'vue-luck-draw'
// import 'amfe-flexible';
Vue.use(LuckDraw);
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
Vue.use(Antd);


import BaiduMap from 'vue-baidu-map'
 
Vue.use( BaiduMap, { ak : 'hvv31Hzt069G4NGGeDbpobdTOoDQhI3r' } )
import './plugins/ant'
import "./plugins/vant";
import './plugins/axios'
import './plugins/utils'
// import VConsole from 'vconsole'
// const vConsole=new VConsole()
// Vue.use(vConsole)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // if (to.meta.wowPage) {
  //     document.title = to.meta.wowPage
  // }
  document.title = '金融小站'
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
