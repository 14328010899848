import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/index'),
  },
  // 任务宝
  {
    path: '/workFission',
    name: 'workFissionIndex',
    component: () => import(/* webpackChunkName: "workFission" */ '@/views/workFission/index'),
  },
  {
    path: '/workFission/speed',
    component: () => import(/* webpackChunkName: "workFission" */ '@/views/workFission/speed'),
  },
  // 抽奖活动
  {
    path: '/lottery',
    name: 'lotteryIndex',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/index'),
  },
  // 抽奖活动——规则说明
  {
    path: '/explain',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/explain'),
  },
  {
    path: '/tips',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/tips'),
  },
  {
    path: '/cashTips',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/cashTips'),
  },
  {
    path: '/Baotips',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/workFission/Baotips'),
  },
  // 互动雷达
  {
    path: '/radar',
    name: 'radar',
    component: () => import(/* webpackChunkName: "radar" */ '@/views/radar/index'),
  },
  // 群打卡
  {
    path: '/roomClockIn',
    name: 'roomClockIn',
    component: () => import(/* webpackChunkName: "roomClockIn" */ '@/views/roomClockIn/index'),
  },
  // 门店活码
  {
    path: '/shopCode',
    name: 'shopCodeIndex',
    component: () => import(/* webpackChunkName: "shopCode" */ '@/views/shopCode/index'),
  },
  // 群裂变
  {
    path: '/roomFission',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/index'),
  },
  // 群裂变海报
  {
    path: '/roomFission/poster',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/poster'),
  },
  // 群裂变——助力进度
  {
    path: '/roomFission/speed',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/speed'),
  },
  // 无限拉群
  {
    path: '/roomInfinitePull',
    component: () => import(/* webpackChunkName: "roomInfinitePull" */ '@/views/roomInfinitePull/index'),
  },
  // 引流链接
  {
    path: '/drainageLink',
    component: () => import(/* webpackChunkName: "drainageLink" */ '@/views/drainageLink/index'),
  },
   // 小程序信息收集
   {
    path: '/miniProgramCorp',
    component: () => import(/* webpackChunkName: "drainageLink" */ '@/views/miniProgramCorp/index'),
  },
  {
    path: "/vcard/record",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/index"
      ),
    meta: { title: "金融工作室", keepAlive:true },
  },
  {
    path: "/electronicCard/caijing",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/caiJing"
      ),
    meta: { title: "财经资讯" },
  },
  {
    path: "/electronicCard/caijing/detail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/components/caiJingDetail"
      ),
    meta: { title: "资讯详情" },
  },
  {
    path: "/electronicCard/activity",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/activity"
      ),
    meta: { title: "热门活动" },
  },
  {
    path: "/electronicCard/activity/detail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/components/activityDetail"
      ),
    meta: { title: "活动详情" },
  },
  {
    path: "/electronicCard/financialinfo",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/financialinfo"
      ),
    meta: { title: "金融产品" },
  },
  {
    path: "/electronicCard/financialinfo/detail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/components/financialDetail"
      ),
    meta: { title: "产品详情" },
  },
  {
    path: "/electronicCard/notice",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/notice"
      ),
    meta: { title: "通知" },
  },
  {
    path: "/electronicCard/notice/detail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */ "@/views/electronicCard/components/noticeDetail"
      ),
    meta: { title: "通知详情" },
  },
  {
    path: "/electronicCard/morningPaper/index",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/morningPaperIndex"
      ),
    meta: {
      title: "早报"
    },
  },
  {
    path: "/electronicCard/eveningPaper/index",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/eveningPaper"
      ),
    meta: {
      title: "晚报"
    },
  },
  {
    path: "/electronicCard/specialTopic",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/specialTopic"
      ),
    meta: {
      title: "专题"
    },
  },
  {
    path: "/electronicCard/specialTopicDetail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/specialTopicDetail"
      ),
    meta: {
      title: "专题详情"
    },
  },
  {
    path: "/electronicCard/circleDetail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/circleDetail"
      ),
    meta: {
      title: "朋友圈详情"
    },
  },
  {
    path: "/poster/create",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/poster/create"),
    meta: {
      title: "创建海报"
    },
  },
  // 微官网-首页
  {
    path: "/bank/index",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/homePage/index"),
    meta: {
      title: "首页",
      wowPage:'微官网'
    },
  },
  // 微官网-支行
  {
    path: "/wowebsite/subbranch",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/subbranch/index"),
    meta: {
      title: "支行",
      wowPage:'微官网'

    },
  },
  // 微官网-个人中心
  {
    path: "/wowebsite/my",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/my/index"),
    meta: {
      title: "个人中心",
      wowPage:'微官网'
    },
  },
  // 微官网-支行-详情
  {
    path: "/wowebsite/branchDetail",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/subbranch/detail"),
    meta: {
      title: "支行详情",
      wowPage:'微官网'
    },
  },
  // 微官网-支行-详情-导航
  {
    path: "/wowebsite/navigation",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/subbranch/navigation"),
    meta: {
      title: "导航",
      wowPage:'微官网'
    },
  },
  // 微官网-支行-详情-广告
  {
    path: "/wowebsite/richtext",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/subbranch/richtext"),
    meta: {
      title: "内容",
      wowPage:'微官网'
    },
  },
  // 微官网-首页-预约
  {
    path: "/wowebsite/appment",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/homePage/appment"),
    meta: {
      title: "预约",
      wowPage:'微官网'
    },
  },
  // 微官网-首页-预约-回调
  {
    path: "/wowebsite/callback",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/homePage/callback"),
    meta: {
      title: "预约",
      wowPage:'微官网'
    },
  },
  // 微官网-首页-银行简介
  {
    path: "/wowebsite/introduce",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/homePage/introduce"),
    meta: {
      title: "银行简介",
      wowPage:'微官网'
    },
  },
  // 微官网-首页-搜索
  {
    path: "/wowebsite/search",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/homePage/search"),
    meta: {
      title: "搜索",
      wowPage:'微官网'
    },
  },
  // 微官网-首页-搜索
  {
    path: "/wowebsite/agree",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/my/agree"),
    meta: {
      title: "用户协议",
      wowPage:'微官网'
    },
  },
  // 微官网-个人中心-我的预约
  {
    path: "/wowebsite/myappment",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/my/appment"),
    meta: {
      title: "我的预约",
      wowPage:'微官网'
    },
  },
  // 微官网-个人中心-我的预约-详情
  {
    path: "/wowebsite/mydetail",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/wowebsite/my/detail"),
    meta: {
      title: "预约详情",
      wowPage:'微官网'
    },
  },
  {
    path: "/identify",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/identify/index"),
    meta: {
      title: "认证"
    },
  },
  {
    path: "/identify/success",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/identify/success"),
    meta: {
      title: "认证成功"
    },
  },
  {
    path: "/redEnvelope/index",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/redEnvelope/index"),
    meta: {
      title: "红包"
    },
  },
  {
    path: "/redEnvelope/detail",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/redEnvelope/detail"),
    meta: {
      title: "红包"
    },
  },
  {
    path: "/billDetail/index",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/billDetail/index"),
    meta: {
      title: "账单详情"
    },
  },
  {
    path: "/poster/festival",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/poster/festival"),
    meta: {
      title: "海报详情"
    },
  },{
    path: "/questions/index",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/questions/index"),
    meta: {
      title: "问答活动"
    }
  },{
      path: "/questionNaire/index",
      component: () =>
        import( /* webpackChunkName: "index" */ "@/views/questions/questionNaire/index"),
      meta: {
        title: "问答活动"
      },
  },{
    path: "/questions/achievement",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/questions/achievement"),
    meta: {
      title: "我的成绩"
    },
  },
  {
    path: "/questions/myAcvin",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/questions/components/myAcvin"),
    meta: {
      title: "我的成绩"
    },
  },
    {
      path: "/questions/certificate",
      component: () =>
        import( /* webpackChunkName: "index" */ "@/views/questions/certificate"),
      meta: {
        title: "我的证书"
      },
},
{
  path: "/questions/poster",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/questions/poster"),
  meta: {
    title: "分享海报"
  },
},
{
  path: "/questions/record",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/questions/record"),
  meta: {
    title: "领奖记录"
  },
},
{
  path: "/questions/answer",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/questions/answer/index"),
  meta: {
    title: "领奖记录"
  },
},
{
  path: "/questions/detailPage",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/questions/answer/detailPage"),
  meta: {
    title: "答题详情"
  },
},
{
  path: "/questionsNaire/detailPage",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/questions/questionNaire/detailPage"),
  meta: {
    title: "答题详情"
  },
},
{
  path: "/customerCode/detail",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/customerCode/index"),
  meta: {
    title: "识客群码"
  },
},
{
  path: "/financial/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/financial/index"),
  meta: {
    title: "理财"
  },
},
{
  path: "/financial/fileDetail",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/financial/components/fileDetail"),
  meta: {
    title: "详情"
  },
},
{
  path: "/financial/list",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/financial/list"),
  meta: {
    title: "列表"
  },
},
{
  path: "/financial/detail",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/financial/detail"),
  meta: {
    title: "列表"
  },
},
{
  path: "/financial/richtext",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/financial/components/richtext"),
  meta: {
    title: "内容"
  },
},
{
  path: "/contact/evaluate",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/contact/evaluate"),
  meta: {
    title: "评价"
  },
}
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
